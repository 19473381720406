import React from 'react';
import { getUser, createUser, updateUser } from './api';
import './App.css';

class App extends React.Component {
  state = {
    user: {
      isLoading: false,
      errors: [],
      data: null
    }
  }

  async componentDidMount() {
    await this.getUser();
  }

  async getUser() {
    this.setState({
      user: {
        isLoading: true,
        errors: [],
        data: null
      }
    })
    try {
      const user = await getUser();
      this.setState({
        user: {
          isLoading: false,
          errors: [],
          data: user
        },
      })
    } catch (e) {
      this.setState({
        user: {
          isLoading: false,
          errors: e.response ? e.response.data.errors : [e.message],
          data: null
        }
      })
    }
  }

  async createUser() {
    this.setState({
      user: {
        isLoading: true,
        errors: [],
        data: null
      }
    })
    try {
      const user = await createUser();
      this.setState({
        user: {
          isLoading: false,
          errors: [],
          data: user
        },
      })
    } catch (e) {
      this.setState({
        user: {
          isLoading: false,
          errors: e.response ? e.response.data.errors : [e.message],
          data: null
        }
      })
    }
  }

  async updateUser({ publicKey, password }) {
    this.setState({
      user: {
        isLoading: true,
        errors: [],
        data: null
      }
    })
    try {
      const user = await updateUser({ publicKey, password });
      this.setState({
        user: {
          isLoading: false,
          errors: [],
          data: user
        },
      })
    } catch (e) {
      console.error(e)
      this.setState({
        user: {
          isLoading: false,
          errors: e.response ? e.response.data.errors : [e.message],
          data: null
        }
      })
    }
  }
  
  render() {
    const { user } = this.state;
    return (
      <React.Fragment>
        <h1 className="index-heading">SFTP Integration</h1>
        <UserInformation
          {...user}
          createUser={this.createUser.bind(this)}
          updateUser={this.updateUser.bind(this)}
        />
      </React.Fragment>
    );
  }
}

function UserInformation ({ data, isLoading, errors, createUser, updateUser }) {
  if (errors.length > 0) {
    return (
      <div>
        <p>The server responded with an error:</p>
        <ul>
          {errors.map(e => <li key={e}>{e}</li>)}
        </ul>
        <p>Please refresh the page and try again. If this issue persists, contact the site administrator with a screenshot of this message.</p>
      </div>
    )
  }

  if (isLoading) {
    return (
      <React.Fragment>
        <p>Loading...</p>
      </React.Fragment>
    )
  }
  
  if (data) {
    return (
      <React.Fragment>
        <div>
          <h3>Usage</h3>
          <p>Use the credentials below to securely log into Sidekick's SFTP server.</p>
          <p>Once logged in, there will be {data.directories.length} folders in your home directory:</p>
          <ul>
            {data.directories.map(d => <li key={d}>{d}</li>)}
          </ul>
          <p>To import a document, simply upload a completed template into it's respective folder. For example, a orders template would go into the orders folder.</p>
          <p>Perodically, our system will download any template that you have uploaded, and import them automatically.</p>
          <p>When a template is imported, you'll get an email with the import   results.</p>
        </div>
        <h3>Your Credentials</h3>
        <div className="detail">
          <div className="name">Host</div>
          <div>sftp.sidekickdelivery.com</div>
        </div>
        <br/>
        <div className="detail">
          <div className="name">Username</div>
          <div>{data.username}</div> 
        </div>
        <br/>
        <div className="detail">
          <div className="name">Password</div>
          <div>{data.password}</div> 
        </div>
        <br/>
        <div className="detail">
          <div className="name">SSH Public Key</div>
          <div style={{overflowWrap: 'break-word'}}>{data.publicKey || '-'}</div> 
        </div>
        <br />
        <UploadPublicKeyForm updateUser={updateUser} />
        <br />
        <RegeneratePasswordForm updateUser={updateUser} />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <p>Welcome to Sidekick's SFTP integration. With this service, you can automatically upload bulk import documents into Sidekick.</p>
      <p>To opt-in to this feature, click the button below.</p>
      <p><button className="btn btn-primary" onClick={createUser}>Create SFTP Account</button></p>
    </React.Fragment>
  )
}

class UploadPublicKeyForm extends React.Component {
  state = {
    error: '',
    publicKey: ''
  }

  setInputState = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  uploadPublicKey = async () => {
    console.log(this.props)
    const regex = /ssh-rsa AAAA[0-9A-Za-z+/]+[=]{0,3} ([^@]+@[^@]+)/
    if (!regex.test(this.state.publicKey)) {
      return this.setState({
        error: `Invalid public key. Testing against ${regex.toString()}`
      })
    }

    await this.props.updateUser({ publicKey: this.state.publicKey })
  }

  render() {
    const { error } = this.state;
    return (
      <React.Fragment>
        <h3>Upload SSH Public Key</h3>
        <p>If you would like to authenticate via SSH key, please upload your public key here.</p>
        <div className="field standard">
          <input name="publicKey" onChange={this.setInputState}></input>
          <button className="btn btn-default" style={{marginLeft: '8px'}} onClick={this.uploadPublicKey}>Upload Public Key</button>
        </div>
        {error ? <div>{error}</div> : null}
      </React.Fragment>
    )
  }
}


function RegeneratePasswordForm({ updateUser }) {
  return (
    <React.Fragment>
      <h3>Regenerate Password</h3>
      <p>If you would like to regenerate your password, click this button.</p>
      <div className="field standard">
        <button className="btn btn-default" onClick={() => updateUser({ password: 'new' })}>Regenerate Password</button>
      </div>
    </React.Fragment>
  )
}

export default App