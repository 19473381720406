import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

const renderTo = el => {
  if (!el) {
    throw new Error('Please provide an element to render to.');
  }

  if (typeof el !== 'string' && !(el instanceof HTMLElement)) {
    throw new Error(
      'Element must be either a string (css selector) or HTMLElement'
    );
  }

  if (typeof el === 'string') {
    el = document.querySelector(el)

    if (!el) {
      throw new Error(
        [
          `Cannot find an element that matches the given selector "${el}".`,
          'If this is unexpected, consider providing an HTMLElement instead.'
        ].join(' ')
      );
    }
  }

  ReactDOM.render(<App />, el);
};

export { renderTo }
